import React from 'react';
import PropTypes from 'prop-types';

import BenefitItem from 'components/benefit-item';

import './styles.scss';

const BENEFITS_ARRAY_LENGTH = 3;

const Diagram = ({ items, classNames }) => {
  const itemTop = items[0];
  const itemLeft = items[1];
  const itemBottom = items[2];

  return (
    <div className={`diagram${classNames ? ` ${classNames}` : ''}`}>
      {itemTop && (
        <BenefitItem
          classNames="diagram__item diagram__item--top"
          title={itemTop.title}
          description={itemTop.description}
          icon={<itemTop.icon />}
        />
      )}
      {itemLeft && (
        <BenefitItem
          classNames="diagram__item diagram__item--left"
          title={itemLeft.title}
          description={itemLeft.description}
          icon={<itemLeft.icon />}
        />
      )}
      {itemBottom && (
        <BenefitItem
          classNames="diagram__item diagram__item--bottom"
          title={itemBottom.title}
          description={itemBottom.description}
          icon={<itemBottom.icon />}
        />
      )}
    </div>
  );
};

Diagram.propTypes = {
  classNames: PropTypes.string,
  items: (props, propName, componentName) => {
    PropTypes.checkPropTypes(
      {
        [propName]: PropTypes.arrayOf(PropTypes.shape({
          title: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
          icon: PropTypes.func.isRequired
        }))
      },
      props,
      propName,
      componentName
    );

    const expectedArrayLength = BENEFITS_ARRAY_LENGTH;
    const arrayLength = props[propName].length;

    if (arrayLength !== expectedArrayLength) {
      return new Error(`
        Invalid array length ${arrayLength} (expected ${expectedArrayLength}) for prop ${propName} supplied to ${componentName}. Validation failed.
      `);
    }

    return null;
  }
};

Diagram.defaultProps = {
  classNames: null,
  items: null
};

export default Diagram;
