import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import Cookies from 'universal-cookie';
import { FormattedMessage, useIntl } from 'react-intl';

import SessionStorageManager from '@youship/utils/SessionStorageManager';

import AuthenticationApi from '@youship/api/authentication';

import {
  EMAIL_PATTERN,
  LETTERS_ONLY_PATTERN,
  NUMBERS_ONLY_PATTERN,
  PASSWORD_MINIMUM_LENGTH,
  PASSWORD_PATTERN,
  PHONE_MAXIMUM_LENGTH,
  PHONE_MINIMUM_LENGTH
} from '@youship/utils/form-validation';

import Button from '@youship/components/objects/button';
import Checkbox from '@youship/components/objects/checkbox';
import Input from '@youship/components/objects/input';
import Select from '@youship/components/objects/select';
import Tooltip from '@youship/components/objects/tooltip';

import infoGrayIcon from '@youship/assets/images/icons/info-gray.svg';

const countries = [
  {
    value: 'PT',
    text: 'Portugal'
  },
  {
    value: 'ES',
    text: 'Spain'
  }
];

const cookies = new Cookies();

const COOKIES_DOMAIN = process.env.GATSBY_COOKIES_DOMAIN;

const PersonalInformationStep = ({ classNames, formClassName, onError, onSubmitting, onSuccess }) => {
  const { control, errors, handleSubmit, register, trigger, watch } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      countryCode: '',
      phone: '',
      password: '',
      matchingPassword: ''
    }
  });

  const [termsAgreed, setTermsAgreed] = useState(false);
  const [failed, setFailed] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [phoneCountryCallingCode, setPhoneCountryCallingCode] = useState(351);
  const [errorMessage, setErrorMessage] = useState(null);

  const countryOptions = countries.map((option) => {
    const icon = option.value === 'pt' ? 'https://flag.pk/flags/4x3/pt.svg' : `https://flag.pk/flags/4x3/${option.value}.svg`;

    return {
      ...option,
      icon
    };
  });

  // Tooltip
  const [showTotalInfoTooltip, setShowTotalInfoTooltip] = useState(false);

  const handleTotalInfoMouseEnter = () => {
    setShowTotalInfoTooltip(true);
  };

  const handleTotalInfoTooltipClose = () => {
    closeTotalInfoTooltipClose();
  };

  const closeTotalInfoTooltipClose = () => {
    setShowTotalInfoTooltip(false);
  };

  const getInputMessage = (name) => {
    if (errors[name]) {
      return errors[name].message;
    }

    return '';
  };

  const getInputStatus = (name) => {
    if (errors[name]) {
      return 'error';
    }

    return 'default';
  };

  const handleFormSubmit = (data) => {
    const formData = {
      ...data,
      countryCode: data.country,
      phoneCode: `+${phoneCountryCallingCode}`
    };

    setIsSubmitting(true);
    onSubmitting();

    AuthenticationApi.signUp({
      email: formData.email,
      firstname: formData.firstName,
      lastname: formData.lastName,
      password: formData.password,
      phonenumber: formData.phone,
      phonecode: formData.phoneCode,
      countrycode: formData.countryCode
    })
      .then((response) => {
        setIsSubmitting(false);

        let successMessage = null;

        if (response) {
          const { activatephone, appkey, message } = response;
          const authenticationToken = appkey ?? null;

          successMessage = message;

          if (authenticationToken) {
            cookies.set('AUTH_TOKEN', authenticationToken, { path: '/', domain: COOKIES_DOMAIN });
            SessionStorageManager.setAuthenticationToken(authenticationToken);
          }

          cookies.set('USER_REQUIRES_PHONE_ACTIVATION', activatephone ? 'true' : 'false', { path: '/', domain: COOKIES_DOMAIN });
        }

        onSuccess(successMessage);

        return response;
      })
      .catch((error) => {
        setFailed(true);
        setIsSubmitting(false);
        setErrorMessage(error?.message || 'Sorry, we were unable to process your request at this time. Please try again later.');
        onError();
      });
  };

  const handleInputChange = (value, props) => {
    const { name } = props;

    props.onChange(value);

    if (errors[name]) {
      trigger(name);
    }

    if (name === 'password' && watch('matchingPassword')) trigger('matchingPassword');
  };

  const isFormValid = () => {
    if (!termsAgreed) return false;

    const inputs = ['firstName', 'lastName', 'email', 'phone', 'password', 'matchingPassword'];

    return inputs.every(input => !!watch(input) && !errors[input]);
  };

  const isButtonDisabled = !isFormValid() || isSubmitting;

  useEffect(() => {
    register({ name: 'firstName' }, {
      pattern: {
        value: LETTERS_ONLY_PATTERN,
        // message: 'First name must have at least 1 letter.'
        message: intl.formatMessage({ id: 'partners.form.first_name.pattern' })
      },
      required: intl.formatMessage({ id: 'partners.form.first_name.required' })
    });

    register({ name: 'lastName' }, {
      pattern: {
        value: LETTERS_ONLY_PATTERN,
        message: intl.formatMessage({ id: 'partners.form.last_name.pattern' })
      },
      required: intl.formatMessage({ id: 'partners.form.last_name.required' })
    });

    register({ name: 'email' }, {
      pattern: {
        value: EMAIL_PATTERN,
        message: intl.formatMessage({ id: 'partners.form.email.pattern' })
      },
      required: intl.formatMessage({ id: 'partners.form.email.required' })
    });

    register({ name: 'country' }, {
      required: intl.formatMessage({ id: 'partners.form.country.instructions' })
    });

    register({ name: 'phone' }, {
      pattern: {
        value: NUMBERS_ONLY_PATTERN,
        message: intl.formatMessage({ id: 'partners.form.phone.pattern' })
      },
      minLength: {
        value: PHONE_MINIMUM_LENGTH,
        message: intl.formatMessage(
          { id: 'partners.form.phone.minlength' },
          { PHONE_MINIMUM_LENGTH }
        )
      },
      maxLength: {
        value: PHONE_MAXIMUM_LENGTH,
        message: intl.formatMessage(
          { id: 'partners.form.phone.maxlength' },
          { PHONE_MAXIMUM_LENGTH }
        )
      },
      required: intl.formatMessage({ id: 'partners.form.phone.required' })
    });

    register({ name: 'password' }, {
      pattern: {
        value: PASSWORD_PATTERN,
        message: intl.formatMessage({ id: 'partners.form.password.pattern' })
      },
      minLength: {
        value: PASSWORD_MINIMUM_LENGTH,
        message: intl.formatMessage(
          { id: 'partners.form.password.minlength' },
          { PASSWORD_MINIMUM_LENGTH }
        )
      },
      required: intl.formatMessage({ id: 'partners.form.password.required' })
    });

    register({ name: 'matchingPassword' }, {
      validate: value => value === watch('password') || intl.formatMessage({ id: 'partners.form.repeat_password.validate' }),
      required: intl.formatMessage({ id: 'partners.form.repeat_password.required' })
    });
  });

  const intl = useIntl();

  return (
    <form
      className={`${formClassName}__step${classNames ? ` ${classNames}` : ''}`}
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <div className={`${formClassName}__content`}>
        <div className={`${formClassName}__input-group`}>
          <div className={`${formClassName}__input-wrapper`}>
            <Controller
              control={control}
              name="firstName"
              render={props => (
                <Input
                  block
                  inputId={props.name}
                  label={intl.formatMessage({ id: 'partners.form.first_name.label' })}
                  message={getInputMessage(props.name)}
                  placeholder={intl.formatMessage({ id: 'partners.form.first_name.placeholder' })}
                  status={getInputStatus(props.name)}
                  onBlur={props.onBlur}
                  onChange={e => handleInputChange(e.target.value, props)}
                />
              )}
            />
          </div>
          <div className={`${formClassName}__input-wrapper`}>
            <Controller
              control={control}
              name="lastName"
              render={props => (
                <Input
                  block
                  inputId={props.name}
                  label={intl.formatMessage({ id: 'partners.form.last_name.label' })}
                  message={getInputMessage(props.name)}
                  placeholder={intl.formatMessage({ id: 'partners.form.last_name.placeholder' })}
                  status={getInputStatus(props.name)}
                  onBlur={props.onBlur}
                  onChange={e => handleInputChange(e.target.value, props)}
                />
              )}
            />
          </div>
        </div>
        <div className={`${formClassName}__input-wrapper`}>
          <Controller
            control={control}
            name="email"
            render={props => (
              <Input
                block
                inputId={props.name}
                label={intl.formatMessage({ id: 'partners.form.email.label' })}
                message={getInputMessage(props.name)}
                placeholder={intl.formatMessage({ id: 'partners.form.email.placeholder' })}
                status={getInputStatus(props.name)}
                onBlur={props.onBlur}
                onChange={e => handleInputChange(e.target.value, props)}
              />
            )}
          />
        </div>
        <div className={`${formClassName}__input-wrapper ${formClassName}__tooltip-wrapper`}>
          <Controller
            control={control}
            name="country"
            render={props => (
              <Select
                inputId="country"
                instructions={intl.formatMessage({ id: 'partners.form.country.instructions' })}
                label="Country"
                message={getInputMessage(props.name)}
                options={countryOptions}
                status={getInputStatus(props.name)}
                value={props.value}
                onBlur={props.onBlur}
                onChange={option => handleInputChange(option, props)}
              />
            )}
          />
          <div
            className={`${formClassName}__country-tooltip`}
            role="tooltip"
            onMouseEnter={handleTotalInfoMouseEnter}
          >
            <img
              alt="Total Info"
              src={infoGrayIcon}
            />
            <Tooltip
              horizontalPosition="center"
              show={showTotalInfoTooltip}
              verticalPosition="top"
              onClose={handleTotalInfoTooltipClose}
            >
              <div className={`${formClassName}__country-tooltip-text`}>
                <FormattedMessage id="partners.form.country.alert" />
              </div>
            </Tooltip>
          </div>
        </div>
        <div className={`${formClassName}__input-wrapper`}>
          <Controller
            control={control}
            name="phone"
            render={props => (
              <Input
                block
                inputId={props.name}
                label={intl.formatMessage({ id: 'partners.form.phone.label' })}
                message={getInputMessage(props.name)}
                placeholder={intl.formatMessage({ id: 'partners.form.phone.placeholder' })}
                status={getInputStatus(props.name)}
                type="phone"
                onBlur={props.onBlur}
                onChange={e => handleInputChange(e.target.value, props)}
                onCountryCallingCodeChange={countryCallingCode => setPhoneCountryCallingCode(countryCallingCode)}
              />
            )}
          />
        </div>
        <div className={`${formClassName}__input-group`}>
          <div className={`${formClassName}__input-wrapper`}>
            <Controller
              control={control}
              name="password"
              render={props => (
                <Input
                  block
                  inputId={props.name}
                  label={intl.formatMessage({ id: 'partners.form.password.label' })}
                  message={getInputMessage(props.name)}
                  placeholder={intl.formatMessage({ id: 'partners.form.password.placeholder' })}
                  status={getInputStatus(props.name)}
                  type="password"
                  onBlur={props.onBlur}
                  onChange={e => handleInputChange(e.target.value, props, 'password')}
                />
              )}
            />
          </div>
          <div className={`${formClassName}__input-wrapper`}>
            <Controller
              control={control}
              name="matchingPassword"
              render={props => (
                <Input
                  block
                  inputId={props.name}
                  label={intl.formatMessage({ id: 'partners.form.repeat_password.label' })}
                  message={getInputMessage(props.name)}
                  placeholder={intl.formatMessage({ id: 'partners.form.repeat_password.placeholder' })}
                  status={getInputStatus(props.name)}
                  type="password"
                  onBlur={props.onBlur}
                  onChange={e => handleInputChange(e.target.value, props)}
                />
              )}
            />
          </div>
        </div>
        <Checkbox
          alignTop
          checked={termsAgreed}
          label={(
            <div className={`${formClassName}__info`}>
              <FormattedMessage id="partners.form.terms_agree" />
              {' '}
              <a
                className={`${formClassName}__info-link`}
                href="/terms-and-conditions"
                rel="noopener noreferrer"
                target="_blank"
                onClick={event => event.stopPropagation()}
              >
                <FormattedMessage id="partners.form.terms_link" />
              </a>
              {' '}
              <FormattedMessage id="partners.form.terms_text" />
              {' '}
              <a
                className={`${formClassName}__info-link`}
                href="/privacy"
                rel="noopener noreferrer"
                target="_blank"
                onClick={event => event.stopPropagation()}
              >
                <FormattedMessage id="partners.form.privacy_link" />
              </a>
            </div>
          )}
          noBorder
          smallMargin
          onChange={value => setTermsAgreed(value)}
        />
        <Button
          block
          context="primary"
          disabled={isButtonDisabled}
          smallVerticalPadding
          text={intl.formatMessage({ id: 'partners.form.continue' })}
          type="submit"
        />
        {failed && (
          <div className={`${formClassName}__error`}>
            {errorMessage}
          </div>
        )}
      </div>
    </form>
  );
};

PersonalInformationStep.propTypes = {
  classNames: PropTypes.string,
  formClassName: PropTypes.string,
  onError: PropTypes.func,
  onSubmitting: PropTypes.func,
  onSuccess: PropTypes.func
};

PersonalInformationStep.defaultProps = {
  classNames: null,
  formClassName: 'partner-registration-form',
  onError: () => { },
  onSubmitting: () => { },
  onSuccess: () => { }
};

export default PersonalInformationStep;
