import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import './styles.scss';

const NumberedList = ({ classNames, list }) => (
  Array.isArray(list) && (
    <ol className={`numbered-list${classNames ? ` ${classNames}` : ''}`}>
      {list.map((item, index) => (
        <li
          key={index}
          className="numbered-list__item"
        >
          <FormattedMessage id={item} />
        </li>
      ))}
    </ol>
  )
);

NumberedList.propTypes = {
  classNames: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired
};

NumberedList.defaultProps = {
  classNames: null,
  list: null
};

export default NumberedList;
