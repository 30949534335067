import React from 'react';
import { FormattedMessage} from 'react-intl';

import Steps from 'components/steps';

import './styles.scss';

const startStep1Image = 'partners/start_step_1.jpg';
const startStep2Image = 'partners/start_step_2.jpg';
const startStep3Image = 'partners/start_step_3.jpg';

const STEPS = [
  {
    index: 1,
    title: 'partners.start.s1.title',
    description: 'partners.start.s1.description',
    image: startStep1Image
  },
  {
    index: 2,
    title: 'partners.start.s2.title',
    description: 'partners.start.s2.description',
    image: startStep2Image
  },
  {
    index: 3,
    title: 'partners.start.s3.title',
    description: 'partners.start.s3.description',
    image: startStep3Image
  }
];

const PartnersStart = () => (
  <div className="partners-start">
    <div className="container">
      <h6 className="partners-start__title">
        <FormattedMessage id="partners.start.title" />
      </h6>
      <h2 className="h2--display partners-start__description">
      <FormattedMessage id="partners.start.description" />
      </h2>
      <Steps
        steps={STEPS}
      />
    </div>
  </div>
);

export default PartnersStart;
