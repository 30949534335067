import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import Img from 'components/image';
import PartnerRegistrationForm from 'components/partner-registration-form';

import './styles.scss';

const headerImage = 'partners/header_image.jpg';

const PartnersHeader = () => (
  <div className="partners-header">
    <div className="container">
      <div className="row">
        <div className="col col-12 col-lg-7 col-xl-6 offset-xl-1 order-lg-2">
          <h1 className="h1--display partners-header__title">
            <FormattedMessage id="partners.header.title" />
          </h1>
          <div className="font-style-body-large partners-header__subtitle">
            <FormattedMessage id="partners.header.subtitle" />
          </div>
          <div className="partners-header__image-wrapper">
            <div className="d-none d-lg-block partners-header__background-element" />
            <Img
              alt="Partners Header"
              classNames="partners-header__image"
              src={headerImage}
            />
          </div>
        </div>
        <div className="col col-12 col-lg-5 order-lg-1">
          <PartnerRegistrationForm
            classNames="partners-header__partner-registration"
          />
        </div>
      </div>
    </div>
  </div>
);

export default injectIntl(PartnersHeader);
