import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const NOTE_CONTEXT_DANGER = 'danger';
const NOTE_CONTEXT_DEFAULT = 'default';
const NOTE_CONTEXT_SUCCESS = 'success';

const NOTE_CONTEXTS = [
  NOTE_CONTEXT_DANGER,
  NOTE_CONTEXT_DEFAULT,
  NOTE_CONTEXT_SUCCESS
];


const Checkbox = ({ alignTop, checked, description, disabled, label, noBorder, note, noteContext, onChange, smallMargin }) => {
  const handleCheckboxKeyDown = (event) => {
    if ((event.keyCode === 13 || event.keyCode === 32) && !disabled) {
      event.preventDefault();
      onChange(!checked);
    }
  };

  return (
    <div
      className={`checkbox${disabled ? ' checkbox--disabled' : ''}${noBorder ? ' checkbox--no-border' : ''}${alignTop ? ' checkbox--align-top' : ''}${smallMargin ? ' checkbox--small-margin' : ''}`}
      onClick={disabled ? () => {} : () => onChange(!checked)}
      onKeyDown={handleCheckboxKeyDown}
      role="button"
      tabIndex={0}
    >
      <div className={`checkbox__input${checked ? ' checkbox__input--checked' : ''}`} />
      <div className="checkbox__content">
        <div className="checkbox__content-header">
          <div className="checkbox__label">
            {label}
          </div>
        </div>
        {!!description && (
          <div className="checkbox__description">
            {description}
          </div>
        )}
      </div>
      {!!note && (
        <div className={`checkbox__note${NOTE_CONTEXTS.includes(noteContext) && noteContext !== NOTE_CONTEXT_DEFAULT ? ` checkbox__note--${noteContext}` : ''}`}>
          {note}
        </div>
      )}
    </div>
  );
};

Checkbox.propTypes = {
  alignTop: PropTypes.bool,
  checked: PropTypes.bool.isRequired,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.node,
  noBorder: PropTypes.bool,
  note: PropTypes.string,
  noteContext: PropTypes.oneOf(NOTE_CONTEXTS),
  onChange: PropTypes.func,
  smallMargin: PropTypes.bool
};

Checkbox.defaultProps = {
  alignTop: false,
  disabled: false,
  description: null,
  label: null,
  noBorder: false,
  note: null,
  noteContext: NOTE_CONTEXT_DEFAULT,
  onChange: () => {},
  smallMargin: false
};


export default Checkbox;
