import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import './styles.scss';

const ConfirmationCode = ({ inputId, label, length, onChange, error, errorMessage }) => {
  const [inputsValues, setInputsValues] = useState(Array(length).fill(''));

  const handleInputChange = (event, index) => {
    const value = event?.target?.value ?? '';

    if (value.length <= 1) {
      setInputsValues((prevState) => {
        const previousInputsValues = [...prevState];

        previousInputsValues[index] = value;

        return previousInputsValues;
      });
    }

    if (value.length === 1) {
      const nextInput = document.getElementById(`${inputId}-${index + 1}`);

      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  useEffect(() => {
    onChange(inputsValues);
  }, [inputsValues]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="confirmation-code">
      {label && (
        <label
          className="input__label"
          htmlFor={inputId}
        >
          <FormattedMessage id={label} />
        </label>
      )}
      <div className="confirmation-code__inputs-wrapper">
        {
          inputsValues.map((inputValue, index) => (
            <input
              key={index}
              className={`confirmation-code__input${inputValue ? ' confirmation-code__input--bordered' : ''}`}
              id={`${inputId}-${index}`}
              value={inputValue}
              onChange={event => handleInputChange(event, index)}
            />
          ))
        }
      </div>
      {!!error && !!errorMessage && (
        <div className="input__error">
          {errorMessage}
        </div>
      )}
    </div>
  );
};

ConfirmationCode.propTypes = {
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  inputId: PropTypes.string,
  label: PropTypes.string,
  length: PropTypes.number,
  onChange: PropTypes.func
};

ConfirmationCode.defaultProps = {
  error: false,
  errorMessage: '',
  inputId: 'confirmation-code-input-id',
  label: null,
  length: 6,
  onChange: () => { }
};

export default ConfirmationCode;
