import React from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '@youship/components/objects/button';
import Diagram from 'components/diagram';
import NumberedList from 'components/numbered-list';

import AvatarIcon from 'images/icons/avatar.inline.svg';
import BoxIcon from 'images/icons/box.inline.svg';
import ListIcon from 'images/icons/list.inline.svg';

import './styles.scss';


const WEB_APP_URL = process.env.GATSBY_WEB_APP_URL;

const DIAGRAM_ITEMS = [
  {
    title: 'partners.diagram.d1.title',
    description: 'partners.diagram.d1.description',
    icon: AvatarIcon
  },
  {
    title: 'partners.diagram.d2.title',
    description: 'partners.diagram.d2.description',
    icon: ListIcon
  },
  {
    title: 'partners.diagram.d3.title',
    description: 'partners.diagram.d3.description',
    icon: BoxIcon
  }
];

const REGISTRATION_STEPS = [
  'partners.diagram.step1',
  'partners.diagram.step2',
  'partners.diagram.step3'
];

const PartnersRegisterSteps = () => (
  <div className="partners-register-steps">
    <div className="container">
      <div className="row d-flex align-items-center">
        <div className="col col-12 col-md-6 col-lg-5 d-none d-md-block">
          <Diagram items={DIAGRAM_ITEMS} />
        </div>
        <div className="col col-12 col-md-6 offset-md-0 col-xl-6 offset-xl-1">
          <h2 className="h1--display partners-register-steps__title">
            <FormattedMessage id="partners.diagram.title" />
          </h2>
          <Diagram
            classNames="d-md-none partners-register-steps__diagram"
            items={DIAGRAM_ITEMS}
          />
          <NumberedList
            classNames="partners-register-steps__list d-none d-md-block"
            list={REGISTRATION_STEPS}
          />
          <Button
            external
            linkProps={{ to: `${WEB_APP_URL}/register` }}
            noArrow
            noShadow
            small
          >
            <FormattedMessage id="partners.diagram.register" />
          </Button>
        </div>
      </div>
    </div>
  </div>
);

export default PartnersRegisterSteps;
