import React from 'react';
import { FormattedMessage } from 'react-intl';

import Separator from 'components/separator';
import TestimonialsSlider from 'components/testimonials-slider';

import leroyMerlinLogo from 'images/partners/logos/leroy_merlin.svg';

import decathlonLogo from 'images/partners/logos/decathlon.png';
import continenteLogo from 'images/partners/logos/continente.svg';
import farmaciaEntregaLogo from 'images/partners/logos/farmacia_entrega.png';
import floristaLogo from 'images/partners/logos/florista.png';
import groceriesLogo from 'images/partners/logos/groceries.png';
// Hidden for now:
// import burgerKingLogo from 'images/partners/logos/burger_king.svg';
// import farmaciaNovaLogo from 'images/partners/logos/farmacia_nova.png';
// import mcdonaldsLogo from 'images/partners/logos/mcdonalds.svg';
// import pullAndBearLogo from 'images/partners/logos/pull_and_bear.svg';
// import sierraLogo from 'images/partners/logos/sierra.png';


import './styles.scss';

const SLIDES = [
  {
    text: 'partners.testimonials.t1',
    image: leroyMerlinLogo
  }
  // Hidden for now:
  // {
  //   text: 'partners.testimonials.t2',
  //   image: burgerKingLogo
  // },
  // {
  //   text: 'partners.testimonials.t3',
  //   image: mcdonaldsLogo
  // },
  // {
  //   text: 'partners.testimonials.t4',
  //   image: continenteLogo
  // },
  // {
  //   text: 'partners.testimonials.t5',
  //   image: pullAndBearLogo
  // },
  // {
  //   text: 'partners.testimonials.t6',
  //   image: farmaciaNovaLogo
  // }
];

const PartnersTestimonials = () => (
  <div className="partners-testimonials">
    <div className="container">
      <div className="row">
        <div className="col col-12 col-lg-5 col-xl-4">
          <h2 className="h1--display partners-testimonials__title">
            <FormattedMessage id="partners.testimonials.title" />
          </h2>
          <div className="partners-testimonials__description">
            <FormattedMessage id="partners.testimonials.description" />
          </div>
        </div>
        <div className="col col-12 col-lg-7 offset-xl-1">
          <TestimonialsSlider slides={SLIDES} />
        </div>
      </div>
      <Separator
        classNames="partners-testimonials__separator"
        light
      />
      <div className="partners-testimonials__logos-grid">
        <div className="row">
          <div className="col col-6 col-md-2 d-flex alig-items-center justify-content-center">
            <div className="partners-testimonials__logo-wrapper">
              <img
                alt=""
                className="partners-testimonials__logo"
                src={leroyMerlinLogo}
              />
            </div>
          </div>
          <div className="col col-6 col-md-2 d-flex alig-items-center justify-content-center">
            <div className="partners-testimonials__logo-wrapper">
              <img
                alt=""
                className="partners-testimonials__logo"
                src={decathlonLogo}
              />
            </div>
          </div>
          <div className="col col-6 col-md-2 d-flex alig-items-center justify-content-center">
            <div className="partners-testimonials__logo-wrapper">
              <img
                alt=""
                className="partners-testimonials__logo"
                src={continenteLogo}
              />
            </div>
          </div>
          <div className="col col-6 col-md-2 d-flex alig-items-center justify-content-center">
            <div className="partners-testimonials__logo-wrapper">
              <img
                alt=""
                className="partners-testimonials__logo"
                src={floristaLogo}
              />
            </div>
          </div>
          <div className="col col-6 col-md-2 d-flex alig-items-center justify-content-center">
            <div className="partners-testimonials__logo-wrapper">
              <img
                alt=""
                className="partners-testimonials__logo"
                src={farmaciaEntregaLogo}
              />
            </div>
          </div>
          <div className="col col-6 col-md-2 d-flex alig-items-center justify-content-center">
            <div className="partners-testimonials__logo-wrapper">
              <img
                alt=""
                className="partners-testimonials__logo"
                src={groceriesLogo}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default PartnersTestimonials;
